'use client'

import { useRef, useEffect } from 'react'
import { useVSCSdkContext } from '@/context/VSCSdkContext'

function Footer() {
  const ref = useRef<HTMLDivElement>(null)
  const { viverseShareComponent, changeLocale } = useVSCSdkContext()

  useEffect(() => {
    if (viverseShareComponent && ref.current) {
      viverseShareComponent.mountFooter({
        $footer: ref.current,
        layoutConfig: {
          type: 4,
        },
        localeConfig: {
          changeLocale,
        },
      })
    }
  }, [viverseShareComponent, changeLocale])

  return <footer ref={ref} />
}

export default Footer
