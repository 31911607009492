'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useEventListener, useMediaQuery, useOnClickOutside } from 'usehooks-ts'
import { motion } from 'framer-motion'
import IconClose from '@/components/assets/icon_close.svg'
import { useConfigsContext } from '@/context/ConfigsContext'
import { useVSCSdkContext } from '@/context/VSCSdkContext'
import { useAuthContext } from '@/context/AuthContext'
import { getCommunityWebUrl } from '@/configs/utils'
import { cn } from '@/lib/utils'

type ViverseCommunityDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
}

function ViverseCommunityDialog({ open, onOpenChange }: ViverseCommunityDialogProps) {
  const dialogRef = useRef<HTMLDivElement>(null)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeContentWindow, setIframeContentWindow] = useState<Window | null>(null)
  const [showNotificationDot, setShowNotificationDot] = useState(false)
  const [isDesktop, setIsDesktop] = useState(useMediaQuery('(min-width: 1024px)'))
  const { viverseShareComponent, isMountedHeader } = useVSCSdkContext()
  const { store, accountId } = useConfigsContext()
  const { isSignedIn } = useAuthContext()

  const communityWebUrl = getCommunityWebUrl()!
  const htcToken = store?.credentials?.htcToken

  const postCommunityInitAuth = useCallback(() => {
    const data = {
      access_token: htcToken,
      account_id: accountId,
    }
    const objJsonStr = JSON.stringify(data)
    const objJsonB64 = Buffer.from(objJsonStr).toString('base64')
    const postData = {
      method: 'initialize-auth',
      payload: { data: objJsonB64 },
    }
    iframeContentWindow!.postMessage(postData, communityWebUrl)
  }, [accountId, communityWebUrl, htcToken, iframeContentWindow])

  const postCommunityFullScreen = useCallback(() => {
    const postData = {
      method: 'community-web-fullscreen',
      payload: { state: !isDesktop },
    }
    iframeContentWindow!.postMessage(postData, communityWebUrl)
  }, [communityWebUrl, isDesktop, iframeContentWindow])

  useEffect(() => {
    if (open && iframeContentWindow && isMountedHeader) {
      postCommunityFullScreen()
      viverseShareComponent?.showCommunityNotify(false)
    }
  }, [open, iframeContentWindow, viverseShareComponent, isMountedHeader, postCommunityFullScreen])

  useEffect(() => {
    if (isSignedIn && iframeContentWindow && viverseShareComponent) {
      postCommunityInitAuth()
      postCommunityFullScreen()
    }
  }, [
    viverseShareComponent,
    iframeContentWindow,
    isSignedIn,
    postCommunityFullScreen,
    postCommunityInitAuth,
  ])

  useEffect(() => {
    if (open) {
      setShowNotificationDot(false)
    }
  }, [open])

  useEffect(() => {
    // Notify share component to show/hide notification dot.
    if (isMountedHeader) {
      viverseShareComponent?.showCommunityNotify(showNotificationDot)
    }
  }, [showNotificationDot, isMountedHeader, viverseShareComponent])

  useOnClickOutside(dialogRef, () => {
    onOpenChange(false)
  })

  useEventListener('resize', () => {
    setIsDesktop(window.innerWidth >= 1024)
  })

  useEventListener('message', (event) => {
    if (event.data?.source?.startsWith('react')) {
      // skip react devtools log
      return
    }

    if (!viverseShareComponent || !isSignedIn) {
      return
    }

    switch (event.data.method) {
      case 'friend-list-modified':
      case 'new-note':
      case 'friend-request-list-modified': {
        setShowNotificationDot(true)
        break
      }
      case 'close-community-web': {
        onOpenChange(false)
        break
      }
    }
  })

  return createPortal(
    <motion.div
      ref={dialogRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: open ? 1 : 0 }}
      transition={{
        ease: 'easeOut',
      }}
    >
      <div
        className={cn(
          'fixed left-1/2 top-1/2 z-[10000] flex size-full -translate-x-1/2 -translate-y-1/2 flex-col overflow-hidden rounded-2xl border-2 border-vive-primary-70 bg-vive-popup p-1 !px-8 outline-none lg:left-auto lg:right-4 lg:max-h-[calc(100dvh-176px)] lg:w-[410px] lg:translate-x-0',
          {
            hidden: !open,
          },
        )}
      >
        <div className="absolute top-0">
          <div className="lg:header-4 relative flex h-[80px] w-full items-center justify-center py-[18px] text-center lg:text-2xl">
            <IconClose className="absolute right-0 lg:hidden" />
          </div>
        </div>
        {isSignedIn && isMountedHeader && (
          <iframe
            allow="camera *; microphone *"
            className={cn('absolute left-0 top-0 size-full flex-1', {
              hidden: !open,
            })}
            ref={iframeRef}
            src={communityWebUrl}
            frameBorder="0"
            allowFullScreen
            onLoad={() => {
              setIframeContentWindow(iframeRef.current?.contentWindow!)
            }}
          />
        )}
      </div>
    </motion.div>,
    document.body,
  )
}

export default ViverseCommunityDialog
